import React from 'react';

import './cooperation.css';

import {useTranslation} from '../../components/translations/languageProvider';

const Cooperation: React.FunctionComponent = () => {
  const {translation} = useTranslation();

  return (
    <div className="cooperation">
      <header>
        <h1>{translation.cooperation}</h1>
      </header>
      <p className="cooperation-example__text">{translation.cooperationExamplesText}</p>
      <div className="cooperation-example">
        <div className="cooperation-example__column1">
          <p className="cooperation-example__title">{translation.dzherelaTitle}</p>
          <p>{translation.dzherelaParagraph1}</p>
          <p>{translation.dzherelaParagraph2}</p>
          <p>{translation.dzherelaParagraph3}</p>
          <p>{translation.dzherelaParagraph4}</p>
        </div>
        <div className="cooperation-example__column2">
          <div className="cooperation-example__logo">
            <a
              href="https://www.bobus.cz/znacka/karpaty-enjoy/"
              target="_blank"
              rel="noreferrer">
              {translation.dzherelaLogo}
            </a>
          </div>
          <div className="cooperation-example__oter-images">
            <img src="./iso.png" />
            <img src="./organic_standard.png" />
          </div>
        </div>
        <section className="cooperation-example__cover-image">
          {translation.dzherelaCoverImage}
        </section>
      </div>
      <div className="cooperation-example">
        <div className="cooperation-example__column1">
          <p className="cooperation-example__title">MG Group</p>
          <p>{translation.mgGroupText}</p>
          <p className="cooperation-example__year">2022</p>
          <ul>{translation.mgGroupListItems2022}</ul>
          <p className="cooperation-example__year">2023</p>
          <ul>{translation.mgGroupListItems2023}</ul>
        </div>
        <div className="cooperation-example__column2">
          <img src="./mg_group.png" className="cooperation-example__logo" />
          <img src="./yurii_popyk.jpeg" className="cooperation-example__director" />
          <p className="cooperation-example__director-name">
            Yurii Popyk
            <br />
            CEO
          </p>
        </div>
        <section className="our-story__images">
          <img src="./mg_01.jpeg" />
          <img src="./mg_02.jpeg" />
          <img src="./mg_03.jpeg" />
        </section>
      </div>
      <h2>{translation.cooperationProcess}</h2>
      <section className="cooperation__image-container">
        {translation.cooperationImage}
      </section>
    </div>
  );
};

export default Cooperation;
