import React from 'react';
import {Link} from 'react-router-dom';

import './footer.css';
import {useTranslation} from '../translations/languageProvider';

const Footer: React.FC = () => {
  const {translation} = useTranslation();

  return (
    <div className="footer">
      <div className="footer__body">
        <div className="footer__columns">
          <div className="footer__column">
            <div className="footer__logo-container">
              <Link to="/">
                <img className="footer__logo" src="./logo.png" />
              </Link>
            </div>
          </div>
          <div className="footer_company-details">
            <div className="footer__column">
              <div className="footer__column-image-container">
                <img src="./map_pin.svg" />
              </div>

              <div className="footer__column-text footer__column-text--bold">
                <div className="footer__title">VBB & Partners s.r.o.</div>
                <p>{translation.addressLine1}</p>
                <p>
                  {translation.addressLine2}, {translation.addressLine3}
                </p>
                <p>{translation.czechRepublic}</p>
                <br />
                <p>{translation.ico}: 17052718</p>
                <p>{translation.dic}: CZ17052718</p>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__column-image-container">
                <img src="./www.svg" />
              </div>
              <div className="footer__column-text">
                <div className="footer__center-vertically-min-height">
                  <p>
                    <a
                      href="mailto:info@vbb-partners.com"
                      target="_blank"
                      rel="noreferrer">
                      info@vbb-partners.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="footer__column">
              <div className="footer__column-image-container">
                <img src="./phone.svg" />
              </div>
              <div className="footer__column-text">
                <div className="footer__center-vertically-min-height">
                  <p>CZ: +420 732 286 880</p>
                  <p>UA: +380 (95) 205 68 14</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>{translation.companyRegistrationInfo}</p>
      </div>
    </div>
  );
};

export default Footer;
