import React from 'react';

import './languageSelector.css';
import {useTranslation} from '../translations/languageProvider';

const LanguageSelector: React.FunctionComponent = () => {
  const {setTranslation} = useTranslation();
  return (
    <div className="language-selector">
      <img
        src="./cz_flag.svg"
        alt="Language selector for Czech language"
        className="language-selector__flag"
        onClick={() => setTranslation('cz')}
      />
      <img
        src="./ua_flag.svg"
        alt="Language selector for Ukrainian language"
        className="language-selector__flag"
        onClick={() => setTranslation('ua')}
      />
      <img
        src="./en_flag.svg"
        alt="Language selector for English language"
        className="language-selector__flag"
        onClick={() => setTranslation('en')}
      />
    </div>
  );
};

export default LanguageSelector;
