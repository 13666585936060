import React, {ReactNode} from 'react';

type TranslationKey =
  | 'businessCases'
  | 'aboutUs'
  | 'references'
  | 'cooperation'
  | 'career'
  | 'contactUs'
  | 'ourPillarsHeader'
  | 'logisticalServicesTitle'
  | 'logisticalServicesText'
  | 'wholesaleTitle'
  | 'wholesaleText'
  | 'consultingTitle'
  | 'consultingText'
  | 'whatMakesUsDifferentTitle'
  | 'trendsTitle'
  | 'trendsText'
  | 'marketTitle'
  | 'marketText'
  | 'customersTitle'
  | 'customersText'
  | 'weAreHere'
  | 'companyRegistrationInfo'
  | 'ourStoryHeader'
  | 'ourStoryParagraph1'
  | 'ourStoryParagraph2'
  | 'ourMissionHeader'
  | 'ourMissionParagraph1'
  | 'ourValuesHeader'
  | 'knowledgeHeader'
  | 'knowledgeText'
  | 'innovationHeader'
  | 'innovationText'
  | 'transparencyHeader'
  | 'transparencyText'
  | 'reliabilityHeader'
  | 'reliabilityText'
  | 'ourTeamHeader'
  | 'directorJobTitle'
  | 'marketingJobTitle'
  | 'logisticsJobTitle'
  | 'itJobTitle'
  | 'cooperationImage'
  | 'cooperationProcess'
  | 'cooperationExamplesText'
  | 'mgGroupText'
  | 'mgGroupListItems2022'
  | 'mgGroupListItems2023'
  | 'dzherelaTitle'
  | 'dzherelaParagraph1'
  | 'dzherelaParagraph2'
  | 'dzherelaParagraph3'
  | 'dzherelaParagraph4'
  | 'dzherelaLogo'
  | 'dzherelaCoverImage'
  | 'ico'
  | 'dic'
  | 'documentNumber'
  | 'bankAccounts'
  | 'headquarters'
  | 'addressLine1'
  | 'addressLine2'
  | 'addressLine3'
  | 'czechRepublic';

export type TranslationStrings = {
  [key in TranslationKey]: string | ReactNode;
};

export type Language = 'cz' | 'en' | 'ua';

export const translations: {[key in Language]: TranslationStrings} = {
  cz: {
    aboutUs: 'O nás',
    references: 'Reference',
    cooperation: 'Spolupráce',
    businessCases: 'Business Cases',
    career: 'Kariéra',
    contactUs: 'Kontaktujte nás',
    ourPillarsHeader: 'Naše služby',
    logisticalServicesTitle: 'Logistické služby',
    logisticalServicesText:
      'Poskytujeme logistické služby a zajištění skladů po celé EU včetně Ukrajiny.',
    wholesaleTitle: 'Velkoobchodní prodej',
    wholesaleText: (
      <>
        Jsme velkoobchodní prodejce a dovozce širokého sortimentu zboží vyrobeného na
        Ukrajině. <br />
        <br />
        Aktuální sortiment naleznete na <a href="https://www.bobus.cz">www.bobus.cz</a>
      </>
    ),
    consultingTitle: 'Konzultační a zprostředkovací činnost',
    consultingText:
      'Provádíme B2B konzultační  a zprostředkovací služby pro výrobce na Ukrajině, které chtějí expandovat na trhy EU také pro evropské společnosti, které chtějí expandovat na Ukrajinu.',
    whatMakesUsDifferentTitle: 'Co nás odlišuje',
    trendsTitle: 'Sledujeme aktuální trendy a inovace',
    trendsText:
      'Stále sledujeme nejnovější trendy a technologie. Díky našemu rozhledu v oboru přinášíme nové a aktuální výrobky.',
    marketTitle: 'Orientujeme se na mezinárodním trhu',
    marketText:
      'Díky našim dlouholetým zkušenostem na mezinárodním trhu jsme schopni vybrat a dovést kvalitní výrobky za velmi příznivé ceny.',
    customersTitle: 'Nasloucháme zákazníkům',
    customersText:
      'S našimi zákazníky jsme ve stálém kontaktu a díky tomu se umíme pružně přizpůsobit jejich aktuálním požadavkům a vyjít jim vstříc.',
    weAreHere: 'Nacházíme se zde',
    companyRegistrationInfo:
      'Společnost je zapsána v Obchodním rejstříku vedeném Městským soudem v Praze, oddíl C, vložka 365841.',
    ourStoryHeader: 'Náš příběh',
    ourStoryParagraph1:
      'Společnost VBB & Partners s.r.o. vznikla v roce 2022 jako vyústění naší humanitární pomoci Ukrajině a jejím občanům v této nelehké době. Na Ukrajině máme rodinu a přátele, které jsme od začátku konfliktu podporovali. Měli jsme tak možnost vidět a zažít to, co se tam děje, na vlastní kůži. Díky našim přátelům a přátelům našich přátel jsme dovezli na Ukrajinu přibližně 10 tun pomoci, tj. jídlo, drogerii, léky a zdravotnický materiál.',
    ourStoryParagraph2:
      'Vše, co začalo jako humanitární pomoc, vyústilo v založení společnosti s mezinárodním přesahem. Zaměřujeme se na hledání a realizaci obchodních příležitostí pro evropské společnosti, kteří chtějí působit na Ukrajině a pomáháme ukrajinským výrobcům prosadit se na EU trhu. Zajišťujeme ucelený balíček služeb, počínaje analýzou trhu, podporou při získání potřebných certifikátů kvality v dané zemi, uvedení produktů na trh až po zajištění logistiky mezi EU a Ukrajinou.',
    ourMissionHeader: 'Naše mise',
    ourMissionParagraph1:
      'Naším posláním je přispívat k budování obchodních vztahů mezi EU a Ukrajinou vytvářením obchodních příležitostí a nabízením výjimečných, kvalitních produktů.',
    ourValuesHeader: 'Naše hodnoty',
    knowledgeHeader: 'Znalosti',
    knowledgeText:
      'Transformujeme znalosti a zkušenosti z korporátního prostředí v přínos pro naše obchodní partnery.',
    innovationHeader: 'Inovace',
    innovationText:
      'Využíváme dostupné informační technologie k nastavení a automatizaci mezinárodních obchodních procesů.',
    transparencyHeader: 'Otevřenost',
    transparencyText:
      'Komunikace je pro nás klíčová. O všem a včas se navzájem informujeme.',
    reliabilityHeader: 'Spolehlivost',
    reliabilityText:
      'Dobré jméno naší společnosti je alfou a omegou. Sami se považujeme za spolehlivé, a proto neděláme kompromisy - spolupracujeme jen se spolehlivými obchodními partnery.',
    ourTeamHeader: 'Náš tým',
    directorJobTitle: 'Jednatel',
    marketingJobTitle: 'Marketing',
    logisticsJobTitle: 'Logistika',
    itJobTitle: 'Správa IT technologií',
    cooperationImage: <img src="./cooperation_cz.png" />,
    cooperationProcess: 'Jak to probíhá',
    cooperationExamplesText:
      'Máme za sebou již několik úspěšných projektů, zde uvádíme výběr úspěchů, kterých jsme s našimi partnery dosáhli.',
    mgGroupText:
      'MG Group je ukrajinská společnost zabývající se návrhem a výrobou LED osvětlení a produktů interiérového designu již od roku 2013',
    mgGroupListItems2022: (
      <>
        <li>Červen - zahájení spolupráce</li>
        <li>Prosinec - realizace prvních zakázek (Proof of Concept)</li>
      </>
    ),
    mgGroupListItems2023: (
      <>
        <li>Únor - Zahájení jednání o spolupráci s IKEA</li>
        <li>Červenec - Zahájení jednání o spolupráci s Inwave (Dánsko)</li>
        <li>
          Červenec - Zajištění skladu v EU s automatizovaným napojením na marketplace
        </li>
        <li>Srpen - Dostupnost produktů MG Group na Marketplace ALZA</li>
        <li>Září - Zahájení procesu pro spolupráci s Marketplace Allegro a Kaufland</li>
      </>
    ),
    dzherelaTitle: 'Dzherela Karpat, LLC',
    dzherelaParagraph1:
      'Dzherela Karpat, LLC je společnost, která se dynamicky rozvíjí v oblasti potravinářských výrobků. Specializuje se na výrobu šťáv, džemů, ovocných a zeleninových zavařenin, k tomu pečlivě vybírá ovoce a zeleninu, která byla vypěstována na ekologicky čistém karpatské území.',
    dzherelaParagraph2:
      'Hlavní předností je čistá karpatská příroda, která vytváří ideální podmínky pro pěstování ovoce, bobulovin a zeleniny. Díky tomu nemusí uměle vylepšovat kvalitu výrobků, to znamená, že nepřidává barviva, aromata a umělé konzervanty.',
    dzherelaParagraph3: (
      <>
        Společnost své produkty vyrábí a dodává na trh pod vlastním brandem -{' '}
        <b>Carpaty Enjoy</b>.
      </>
    ),
    dzherelaParagraph4: (
      <>
        Poznejte chuť Karpat s produkty od <b>Carpaty Enjoy</b>!
      </>
    ),
    dzherelaLogo: <img src="./karpaty_logo_en.webp" />,
    dzherelaCoverImage: <img src="./capraty_enjoy_family_cz.jpg" />,
    ico: 'IČO',
    dic: 'DIČ',
    documentNumber: (
      <>
        Spisová značka: <b>C 365841</b> vedená u Městského soudu v Praze
      </>
    ),
    bankAccounts: 'Bankovní účty',
    headquarters: 'Sídlo',
    addressLine1: 'Zbraslavská 12/11',
    addressLine2: 'Praha 5 - Malá Chuchle',
    addressLine3: '159 00',
    czechRepublic: 'Česká republika'
  },
  en: {
    aboutUs: 'About us',
    references: 'References',
    cooperation: 'Cooperation',
    businessCases: 'Business Cases',
    career: 'Career',
    contactUs: 'Contact us',
    ourPillarsHeader: 'Our services',
    logisticalServicesTitle: 'Logistics services',
    logisticalServicesText:
      'We provide logistics services and procure warehouses across the EU, including Ukraine.',
    wholesaleTitle: 'Wholesale',
    wholesaleText: (
      <>
        We are a wholesaler and importer of a wide range of goods manufactured in Ukraine.
        <br />
        <br />
        You can find the current assortment at{' '}
        <a href="https://www.bobus.cz">www.bobus.cz</a>
      </>
    ),
    consultingTitle: 'Consulting and intermediary activity',
    consultingText:
      'We provide B2B consulting and mediation services for manufacturers in Ukraine who want to expand to EU markets and also for European companies who want to expand to Ukraine.',
    whatMakesUsDifferentTitle: 'What makes us different',
    trendsTitle: 'We follow current trends and innovations',
    trendsText:
      'We are always following the latest trends and technologies. Thanks to our insight into the field, we bring new and up-to-date products.',
    marketTitle: 'We focus on the international market',
    marketText:
      'Thanks to our many years of experience on the international market, we are able to select and deliver quality products at very favorable prices.',
    customersTitle: 'We listen to customers',
    customersText:
      'We are in constant contact with our customers and thanks to this we are able to flexibly adapt to their current requirements and meet them.',
    weAreHere: 'We are located here',
    companyRegistrationInfo:
      'The company is registered in the Commercial Register maintained by the Municipal Court in Prague, section C, insert 365841.',
    ourStoryHeader: 'Our Story',
    ourStoryParagraph1:
      'The company VBB & Partners s.r.o. was established in 2022 as a result of our humanitarian aid to Ukraine and its citizens in this difficult time. We have family and friends in Ukraine who we have supported since the beginning of the conflict. We had the opportunity to see and experience what was happening there firsthand. Thanks to our friends and friends of our friends, we brought approximately 10 tons of aid to Ukraine, i.e. food, drugstores, medicines and medical supplies.',
    ourStoryParagraph2:
      'Everything that started as humanitarian aid resulted in the establishment of companies with international reach. We focus on finding and implementing business opportunities for European companies that want to operate in Ukraine and we help Ukrainian manufacturers establish themselves on the EU market. We provide a complete package of services, starting with market analysis, support in obtaining the necessary quality certificates in a given country, launching products on the market, and ending with logistics between the EU and Ukraine.',
    ourMissionHeader: 'Our Mission',
    ourMissionParagraph1:
      'Our mission is to contribute to building trade relations between the EU and Ukraine by creating business opportunities and offering exceptional, quality products.',
    ourValuesHeader: 'Our Values',
    knowledgeHeader: 'Knowledge',
    knowledgeText:
      'We transform knowledge and experience from the corporate environment into benefits for our business partners',
    innovationHeader: 'Innovations',
    innovationText:
      'We use available information technologies to set up and automate international business processes',
    transparencyHeader: 'Transparency',
    transparencyText:
      'Communication is crucial for us. We keep each other informed in a timely manner.',
    reliabilityHeader: 'Reliability',
    reliabilityText:
      'The good name of our company is our alpha and omega. We consider ourselves reliable and therefore do not do any compromises and work only with reliable business partners.',
    ourTeamHeader: 'Our team',
    directorJobTitle: 'CEO',
    marketingJobTitle: 'Marketing',
    logisticsJobTitle: 'Logistics',
    itJobTitle: 'Head of IT',
    cooperationImage: <img src="./cooperation_en.png" />,
    cooperationProcess: 'How does it work',
    cooperationExamplesText:
      'We have already completed several successful projects - here is a selection of achievements we have reached with our partners.',
    mgGroupText:
      'MG Group is a Ukrainian design company and manufacturing LED lighting and interior design products since 2013.',
    mgGroupListItems2022: (
      <>
        <li>June - beginning of cooperation</li>
        <li>December - first orders (Proof of Concept)</li>
      </>
    ),
    mgGroupListItems2023: (
      <>
        <li>February - Start of negotiations on cooperation with IKEA</li>
        <li>July - Start of negotiations on cooperation with Inwave (Denmark)</li>
        <li>
          July - Provision of a warehouse in the EU with an automated connection to the
          marketplace
        </li>
        <li>August - Availability of MG Group products on the ALZA Marketplace</li>
        <li>
          September - Initiation of the process for cooperation with Marketplaces Allegro
          and Kaufland
        </li>
      </>
    ),
    dzherelaTitle: 'Dzherela Karpat, LLC',
    dzherelaParagraph1:
      'Dzherela Karpat, LLC is a dynamically developing company in the field of food products. It specializes in the production of juices, jams, fruit preserves and vegetable preserves.',
    dzherelaParagraph2:
      'Its main advantage is the pure Carpathian nature, which creates ideal conditions for growing fruits, berries and vegetables. Thanks to this, it is not necessary to artificially improve the quality of products, that is, it does not add dyes, aromas or artificial preservatives.',
    dzherelaParagraph3: (
      <>
        The company manufactures its products and manufactures them on the market under
        its own brand - <b>Carpaty Enjoy</b>.
      </>
    ),
    dzherelaParagraph4: (
      <>
        Experience the taste of Karpat with products from <b>Carpaty Enjoy</b>!
      </>
    ),
    dzherelaLogo: <img src="./karpaty_logo_en.webp" />,
    dzherelaCoverImage: <img src="./capraty_enjoy_family_en.jpg" />,
    ico: 'Company ID',
    dic: 'Tax ID',
    documentNumber: (
      <>
        Document number: <b>C 365841</b> kept at the Municipal Court in Prague
      </>
    ),
    bankAccounts: 'Bank accounts',
    headquarters: 'Headquarters',
    addressLine1: 'Zbraslavská 12/11',
    addressLine2: 'Praha 5 - Malá Chuchle',
    addressLine3: '159 00',
    czechRepublic: 'Czech Republic'
  },
  ua: {
    aboutUs: 'Про нас',
    references: 'Відгуки',
    cooperation: 'Відгуки',
    businessCases: 'Бізнес-кейси',
    career: "Кар'єра",
    contactUs: "Зв'яжіться з нами",
    ourPillarsHeader: 'Наші послуги',
    logisticalServicesTitle: 'Логістика',
    logisticalServicesText:
      'Забезпечуємо послуги логістики та склади по всій території ЄС і в Україні.',
    wholesaleTitle: 'Оптова торгівля',
    wholesaleText: (
      <>
        Займаємося оптовою торгівлею та імпортом широкого асортименту товарів українського
        виробництва до ЄС. <br />
        <br /> З актуальним асортиментом можна ознайомитися тут{' '}
        <a href="https://www.bobus.cz">www.bobus.cz</a>
      </>
    ),
    consultingTitle: 'Консалтинг та посередницька діяльність',
    consultingText:
      'Надаємо B2B консалтингові та посередницькі послуги для виробників в Україні, які хочуть вийти на ринок ЄС, а також для європейських компаній, які хочуть розширити свою діяльність в Україні.',
    whatMakesUsDifferentTitle: 'Що нас відрізняє',
    trendsTitle: 'Слідкуємо за сучасними тенденціями та інноваціями',
    trendsText:
      'Ми слідкуємо за останніми тенденціями та технологіями. Завдяки нашому розумінню сфери ми пропонуємо нові та сучасні продукти.',
    marketTitle: 'Орієнтуємося на міжнародний ринок',
    marketText:
      'Завдяки багаторічному досвіду роботи на міжнародному ринку ми можемо підібрати та доставити якісну продукцію за дуже вигідними цінами.',
    customersTitle: 'Прислухаємося до клієнтів',
    customersText:
      'Ми перебуваємо в постійному контакті з нашими клієнтами та вміємо адаптуватися до їхніх вимог і виконати їх.',
    weAreHere: 'Ми знаходимося тут',
    companyRegistrationInfo:
      'Компанія зареєстрована в Комерційному реєстрі, який веде Міський суд у Празі, розділ C, вставка 365841.',
    ourStoryHeader: 'Наша історія',
    ourStoryParagraph1:
      'Компанія VBB & Partners s.r.o. була створена у 2022 році в результаті нашої гуманітарної допомоги Україні та її громадянам у цей важкий час. У нас є родина та друзі в Україні, яких ми підтримуємо з початку війни. Ми мали можливість побачити та відчути, що там відбувається, на власні очі. Завдяки нашим друзям і друзям наших друзів ми привезли в Україну приблизно 10 тонн допомоги, тобто продукти харчування, засоби особистої гігієни, ліки та медичне приладдя.',
    ourStoryParagraph2:
      'Усе, що починалося як гуманітарна допомога, призвело до створення компанії із міжнародним розмахом. Ми зосереджені на пошуку та реалізації бізнес-можливостей для європейських компаній, які хочуть працювати в Україні, і допомагаємо українським виробникам утвердитися на ринку ЄС. Ми надаємо повний пакет послуг, починаючи з аналізу ринку, підтримки в отриманні необхідних сертифікатів якості в тій чи іншій країні, введення продукції на ринок і закінчуючи логістикою між ЄС та Україною.',
    ourMissionHeader: 'Наша місія',
    ourMissionParagraph1:
      'Наша місія полягає в тому, щоб сприяти розбудові торговельних відносин між ЄС та Україною, створюючи можливості для бізнесу та пропонуючи виняткову якісну продукцію.',
    ourValuesHeader: 'Наші цінності',
    knowledgeHeader: 'Знання',
    knowledgeText:
      'Ми перетворюємо наше знання та досвід корпоративного середовища на користь для наших ділових партнерів.',
    innovationHeader: 'Інноваційність',
    innovationText:
      'Ми використовуємо доступні інформаційні технології для налагодження та автоматизації міжнародних бізнес-процесів.',
    transparencyHeader: 'Відкритість',
    transparencyText:
      'Ефективна комунікація - ключ успіху бізнесу. Інформуємо один одного про все і вчасно.',
    reliabilityHeader: 'Надійність',
    reliabilityText: `Добре ім'я нашої компанії - це альфа і омега. Ми вважаємо себе надійними, тому не йдемо на компроміси – працюємо лише з надійними діловими партнерами.`,
    ourTeamHeader: 'Наша команда',
    directorJobTitle: 'Директор',
    marketingJobTitle: 'Маркетинг',
    logisticsJobTitle: 'Логістика',
    itJobTitle: 'Управління ІТ технологіями',
    cooperationImage: <img src="./cooperation_ua.png" />,
    cooperationProcess: 'Як це відбувається',
    cooperationExamplesText:
      'Ми вже успішно завершили кілька проєктів, ось вибір досягнень, яких ми досягли разом з нашими партнерами.',
    mgGroupText:
      'MG Group — українська дизайнерська компанія з 2013 року займається виробництвом світлодіодного освітлення та дизайну інтер’єру.',
    mgGroupListItems2022: (
      <>
        <li>Червень - початок співпраці</li>
        <li>Грудень - виконання перших замовлень (Proof of Concept)</li>
      </>
    ),
    mgGroupListItems2023: (
      <>
        <li>Лютий - Початок переговорів про співпрацю з IKEA</li>
        <li>Липень - Початок переговорів про співпрацю з Inwave (Данія)</li>
        <li>
          Липень – Надання складу в ЄС з автоматизованим підключенням до маркетплейсу
        </li>
        <li>Серпень - доступність продуктів MG Group на ALZA Marketplace</li>
        <li>Вересень – початок процесу співпраці з Marketplace Allegro та Kaufland</li>
      </>
    ),
    dzherelaTitle: 'ТОВ «Джерела Карпат»',
    dzherelaParagraph1:
      'ТОВ «Джерела Карпат» - компанія, яка динамічно розвивається у сфері виробництва продуктів харчування. Спеціалізується на виробництві соків, джемів, консервації, для яких ретельно відбирає фрукти та овочі, вирощені в екологічно чистій зоні Карпат.',
    dzherelaParagraph2:
      'Його головною перевагою є чиста карпатська природа, яка створює ідеальні умови для вирощування фруктів, ягід та овочів. Завдяки цьому не доводиться штучно підвищувати якість продуктів, тобто не додавати барвники, ароматизатори та штучні консерванти.',
    dzherelaParagraph3: (
      <>
        Підприємство виробляє та постачає на ринок продукцію під власною торговою маркою -{' '}
        <b>Carpaty Enjoy</b>.
      </>
    ),
    dzherelaParagraph4: (
      <>
        Пізнай смак Карпат з продуктами від <b>Carpaty Enjoy</b>!
      </>
    ),
    dzherelaLogo: <img src="./karpaty_logo_ua.webp" />,
    dzherelaCoverImage: <img src="./capraty_enjoy_family_ua.jpg" />,
    ico: 'Код ЄДРПОУ',
    dic: 'ІПН',
    documentNumber: (
      <>
        Номер справи: <b>C 365841</b> перебуває в муніципальному суді в Празі
      </>
    ),
    bankAccounts: 'Банківські рахунки',
    headquarters: 'Офіс',
    addressLine1: 'вул. Збраславска 12/11',
    addressLine2: 'м. Прага 5',
    addressLine3: '159 00',
    czechRepublic: 'Чеська Республіка'
  }
};
