import React from 'react';

import './aboutUs.css';

import {useTranslation} from '../../components/translations/languageProvider';
import Tile from '../../components/tile/tile';

const AboutUs: React.FunctionComponent = () => {
  const {translation} = useTranslation();

  return (
    <div className="about-us">
      <header>
        <h1>{translation.aboutUs}</h1>
      </header>
      <h2>{translation.ourStoryHeader}</h2>
      <section className="our-story">
        <p>{translation.ourStoryParagraph1}</p>
        <p>{translation.ourStoryParagraph2}</p>
      </section>
      <section className="our-story__images">
        <img src="./our_story_1.jpg" />
        <img src="./our_story_2.jpg" />
        <img src="./our_story_3.jpg" />
      </section>
      <h2>{translation.ourMissionHeader}</h2>
      <section className="our-mission">
        <p>{translation.ourMissionParagraph1}</p>
      </section>
      <h2>{translation.ourValuesHeader}</h2>
      <section className="our-values">
        <Tile
          image="./knowledge.svg"
          title={translation.knowledgeHeader}
          text={translation.knowledgeText}
          noTitleMinHeight
        />
        <Tile
          image="./innovation.svg"
          title={translation.innovationHeader}
          text={translation.innovationText}
          noTitleMinHeight
        />
        <Tile
          image="./transparency.svg"
          title={translation.transparencyHeader}
          text={translation.transparencyText}
          noTitleMinHeight
        />
        <Tile
          image="./reliability.svg"
          title={translation.reliabilityHeader}
          text={translation.reliabilityText}
          noTitleMinHeight
        />
      </section>
      <h2>{translation.ourTeamHeader}</h2>
      <section className="our-team">
        <div className="our-team__member">
          <img src="./vladimir_bobusky.jpg" />
          <p className="our-team__name">Ing. Vladimír Bobuský</p>
          <p className="our-team__job-title">{translation.directorJobTitle}</p>
          <p>
            CZ: +420 732 286 880 <br />
            UA: +380 (95) 205 68 14
            <br />
            <a
              href="mailto:vladimir.bobusky@vbb-partners.com"
              target="blank"
              className="our-team__email">
              vladimir.bobusky
              <br />
              @vbb-partners.com
            </a>
          </p>
        </div>
        <div className="our-team__member">
          <img src="./bohdana_bobuska.jpg" />
          <p className="our-team__name">Ing. Bohdana Bobuská</p>
          <p className="our-team__job-title">{translation.marketingJobTitle}</p>
          <p>
            CZ: +420 607 585 181 <br />
            UA: +380 (99) 729 25 84
            <br />
            <a
              href="mailto:bohdana.bobuska@vbb-partners.com"
              target="blank"
              className="our-team__email">
              bohdana.bobuska
              <br />
              @vbb-partners.com
            </a>
          </p>
        </div>
        <div className="our-team__member">
          <img src="./taras_kyrlyk.jpeg" />
          <p className="our-team__name">Bc. Taras Kyrlyk</p>
          <p className="our-team__job-title">{translation.logisticsJobTitle}</p>
          <p>
            UA: +380 (95) 183 78 97
            <br />
            <a
              href="mailto:taras.kyrlyk@vbb-partners.com"
              target="blank"
              className="our-team__email">
              taras.kyrlyk
              <br />
              @vbb-partners.com
            </a>
          </p>
        </div>
        <div className="our-team__member">
          <img src="./jakub_kohoutek.jpg" />
          <p className="our-team__name">Ing. Jakub Kohoutek</p>
          <p className="our-team__job-title">{translation.itJobTitle}</p>
          <a
            href="mailto:jakub.kohoutek@vbb-partners.com"
            target="blank"
            className="our-team__email"
            style={{paddingTop: 0}}>
            jakub.kohoutek
            <br />
            @vbb-partners.com
          </a>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
