import React, {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import {Language, translations, TranslationStrings} from './translations';

const getDefaultLanguage = (): Language => {
  const {language} = navigator;

  if (language.startsWith('en')) {
    return 'en';
  }

  if (language.startsWith('ru')) {
    return 'ua';
  }

  return 'cz';
};

const defaultTranslation = translations[getDefaultLanguage()];

const LanguageContext = createContext<{
  translation: TranslationStrings;
  setTranslation: (language: Language) => void;
}>({
  translation: defaultTranslation,
  setTranslation: () => {}
});

export const useTranslation = () => useContext(LanguageContext);

const LanguageProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [currentTranslation, setCurrentTranslation] =
    useState<TranslationStrings>(defaultTranslation);
  const setTranslation = useCallback((language: Language) => {
    setCurrentTranslation(translations[language]);
  }, []);

  const value = {
    translation: currentTranslation,
    setTranslation
  };

  return <LanguageContext.Provider value={value}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;
