import React from 'react';

import Map from '../../components/map';

import './contact.css';
import {useTranslation} from '../../components/translations/languageProvider';

const Contact: React.FunctionComponent = () => {
  const {translation} = useTranslation();

  return (
    <div className="contact">
      <header>
        <h1>{translation.contactUs}</h1>
      </header>
      <div className="contact__content">
        <div className="contact__container">
          <p className="contact__title">VBB & Partners s.r.o.</p>
          <div className="contact__email">
            <img src="./www.svg" />
            <p>
              <a href="mailto:info@vbb-partners.com" target="_blank" rel="noreferrer">
                info@vbb-partners.com
              </a>
            </p>
          </div>
          <p>
            <b>{translation.ico}</b>: 17052718
          </p>
          <p>
            <b>{translation.dic}</b>: CZ17052718
          </p>
          <p>{translation.documentNumber}</p>
          <p className="bank-accounts__title">
            <b>{translation.bankAccounts}</b>:
          </p>
          <div className="bank-accounts">
            <p>
              CZK: 2802189975/2010
              <br />
              IBAN: CZ7820100000002802189975
            </p>
            <p>
              EUR: 2802370598/2010
              <br />
              IBAN: CZ2920100000002802370598
            </p>
          </div>
          <p className="headquarters">
            <b>{translation.headquarters}</b>: {translation.addressLine1},{' '}
            {translation.addressLine2}, {translation.addressLine3},{' '}
            {translation.czechRepublic}
          </p>
        </div>
        <div className="map__container">
          <Map />
        </div>
      </div>
    </div>
  );
};

export default Contact;
