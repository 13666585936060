import React from 'react';
import {BrowserRouter as Router, Route, Routes, useLocation} from 'react-router-dom';
import classNames from 'classnames';

import Navigation from './components/navigation/navigation';

import Home from './routes/home/home';

import './app.css';
import LanguageProvider from './components/translations/languageProvider';
import Contact from './routes/contact/contact';
import Footer from './components/footer/footer';
import AboutUs from './routes/aboutUs/aboutUs';
import Cooperation from './routes/cooperation/cooperation';
import ScrollToTop from './components/scrollToTop/scrollToTop';

type Props = {
  children: React.ReactNode;
};

const Page: React.FunctionComponent<Props> = ({children}) => {
  const location = useLocation();
  const className = location.pathname === '/' ? 'homepage' : '';

  return <div className={classNames('page', className)}>{children}</div>;
};

const App: React.FC = () => {
  return (
    <Router>
      <ScrollToTop />
      <LanguageProvider>
        <Page>
          <Navigation />
          <main className="content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/cooperation" element={<Cooperation />} />
              <Route path="/contact" element={<Contact />} />
            </Routes>
          </main>
          <Footer />
        </Page>
      </LanguageProvider>
    </Router>
  );
};

export default App;
