import React, {useState} from 'react';
import Spinner from '../../components/spinner';

import './map.css';

const Map: React.FunctionComponent = () => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="map">
      {loading && <Spinner />}

      <iframe
        className={loading ? 'map--loading' : 'map--loaded'}
        title="google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.262613744053!2d14.390789315682099!3d50.02517477941888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b9699112dbb3d%3A0x94f687816d1d4d36!2sZbraslavsk%C3%A1%2012%2C%20159%2000%20Velk%C3%A1%20Chuchle-Mal%C3%A1%20Chuchle!5e0!3m2!1scs!2scz!4v1653335476933!5m2!1scs!2scz"
        allowFullScreen
        onLoad={(): void => setLoading(false)}
      />
    </div>
  );
};

export default Map;
