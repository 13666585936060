import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

import './navigation.css';
import {useTranslation} from '../translations/languageProvider';
import Logo from './logo';
import LanguageSelector from './languageSelector';

const Navigation: React.FunctionComponent = () => {
  const [visible, setVisibility] = useState(false);
  const {translation} = useTranslation();

  const handleMobileMenuClick = (): void => {
    setVisibility(!visible);
  };

  return (
    <nav className="navigation">
      <Link to="/">
        <Logo />
      </Link>
      <ul
        className={classNames('navigation__list', {
          'navigation__list--hidden': !visible
        })}
        onClick={(): void => setVisibility(false)}>
        <li>
          <Link to="/">
            <img src="./home.svg" className="navigation__home-icon" />
          </Link>
        </li>
        <li>
          <Link to="/about">{translation.aboutUs}</Link>
        </li>
        <li>
          <Link to="/cooperation">{translation.cooperation}</Link>
        </li>
        <li>
          <Link to="/contact">{translation.contactUs}</Link>
        </li>
      </ul>
      <div className="navigation__hamburger-icon" onClick={handleMobileMenuClick}>
        {visible ? <span>&#10005;</span> : <span>&#9776;</span>}
      </div>
      <LanguageSelector />
    </nav>
  );
};

export default Navigation;
