import React from 'react';

import Tile from '../../components/tile/tile';

import './home.css';
import {useTranslation} from '../../components/translations/languageProvider';

const Home: React.FunctionComponent = () => {
  const {translation} = useTranslation();

  return (
    <div className="home">
      <header>
        <h1>{translation.ourPillarsHeader}</h1>
      </header>
      <section className="tiles">
        <Tile
          image="./wholesale.svg"
          title={translation.wholesaleTitle}
          text={translation.wholesaleText}
        />
        <Tile
          image="./logistics.svg"
          title={translation.logisticalServicesTitle}
          text={translation.logisticalServicesText}
        />
        <Tile
          image="./consulting.svg"
          title={translation.consultingTitle}
          text={translation.consultingText}
        />
      </section>
      <h1>{translation.whatMakesUsDifferentTitle}</h1>
      <section className="tiles">
        <Tile
          image="./trends.svg"
          title={translation.trendsTitle}
          text={translation.trendsText}
        />
        <Tile
          image="./market.svg"
          title={translation.marketTitle}
          text={translation.marketText}
        />
        <Tile
          image="./customers.svg"
          title={translation.customersTitle}
          text={translation.customersText}
        />
      </section>
    </div>
  );
};

export default Home;
