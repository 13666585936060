import React, {ReactNode} from 'react';
import classNames from 'classnames';

import './tile.css';

type TileProps = {
  image: string;
  title: string | ReactNode;
  text: string | ReactNode;
  noTitleMinHeight?: boolean;
};

const Tile: React.FunctionComponent<TileProps> = ({
  image,
  title,
  text,
  noTitleMinHeight
}) => {
  return (
    <div className="tile">
      <img className="tile__image" src={image} />
      <div
        className={classNames(
          'tile__title',
          noTitleMinHeight || 'tile__title--min-height'
        )}>
        {title}
      </div>
      <div className="tile__text">{text}</div>
    </div>
  );
};

export default Tile;
